<template>
    <div class="home">
        <div class="w-100">
            <order-list />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import OrderList from "./components/orderList.vue";
export default defineComponent({
    name: "Orders",
    components: { OrderList },
});
</script>
<style scoped>
.color-icon {
    color: white;
}
</style>
