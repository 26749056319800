<template>
    <div>
        <div class="w-100">
            <Header title="" />
        </div>

        <div class="w-100 d-flex flex-row flex-wrap">
            <left-side-bar />
            <div class="ml-2" style="width: 90vw">
                <div
                    class="
                        d-flex
                        flex-row flex-wrap
                        justify-between
                        align-items-center
                        w-100
                    "
                >
                    <h1 class="font-xx-large font-weight-bold">Órdenes</h1>
                </div>
                <!-- <div>
                    <tab-vehicle-list
                        @editVehicle="rowClicked"
                        :tab-role="tabRole"
                    />
                </div> -->
                <el-divider></el-divider>
                <h3 style="font-size: 21px; font-weight: bold">Órdenes</h3>

                <!-- /// LA BUSQUEDA /// -->
                <el-col :span="8">
                    <div style="width: 350px">
                        <el-input v-model="searchByquery" placeholder="Buscar">
                            <template style="color: white" #append>
                                <el-button
                                    style="background-color: #3f69a6"
                                    @click="searchByQuery('')"
                                    type="primary"
                                >
                                    <i
                                        style="color: white"
                                        class="el-icon-search el-icon-center"
                                    ></i>
                                </el-button>
                            </template>
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div>
                        <el-button
                            size="mini"
                            style="
                                border: none;
                                background-color: transparent;
                                border-color: transparent;
                            "
                        >
                            <i
                                class="material-icons text-secondary"
                                style="font-size: 18px"
                                >filter_alt</i
                            >
                        </el-button>
                    </div>
                </el-col>
                <br />
                <br />
                <br />
                <!-- /// LA TABLA /// -->
                <el-table
                    v-loading="loading"
                    ref="multipleTable"
                    :data="tableData"
                    style="width: 100%; max-width: 90vw"
                    @selection-change="handleSelectionChange"
                    @row-click="rowClicked"
                >
                    <el-table-column
                        property="orderNumber"
                        label="Código"
                        width="200"
                    />
                    <el-table-column property="date" label="Fecha" width="200">
                        <template #default="scope">
                            {{
                                moment(scope.row.creationDate).format(
                                    "ddd MMM DD, YYYY"
                                )
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        property="processStatus"
                        label="Estatus de proceso"
                        width="200"
                    />
                    <el-table-column
                        property="orderStatus"
                        label="Estatus de la orden"
                        width="200"
                    />
                    <el-table-column
                        property="neighbourhood"
                        label="Sector"
                        width="200"
                    />
                    <el-table-column
                        property="delivery"
                        label="Delivery"
                        width="200"
                    />
                    <el-table-column label="Acciones">
                        <template #default="scope">
                            {{ scope.row.orderStatusId }}
                            <el-button
                                @click="handlePassed(scope.$index, scope.row)"
                                v-if="scope.row.processStatusId == 2"
                                type="success"
                                circle
                            >
                                <i
                                    style="font-size: 14px"
                                    class="material-icons"
                                    >check</i
                                ></el-button
                            >
                            <el-button
                                @click="handleFail(scope.$index, scope.row)"
                                v-if="scope.row.processStatusId == 2"
                                type="danger"
                                circle
                            >
                                <i
                                    style="font-size: 14px"
                                    class="material-icons"
                                    >close</i
                                ></el-button
                            >
                            <el-button
                                @click="handlePacked(scope.$index, scope.row)"
                                v-if="scope.row.processStatusId == 5"
                                type="success"
                                circle
                            >
                                <i
                                    style="font-size: 16px"
                                    class="material-icons"
                                    >redeem</i
                                ></el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>

                <br />
                <br />
                <el-row :gutter="20">
                    <el-col :span="12" :offset="6">
                        <div class="block">
                            <el-pagination
                                layout="prev, pager, next"
                                :page-size="page_size"
                                :page-count="total_pages"
                                :total="total_count"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                            ></el-pagination>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import Header from "@/components/dashboard/include/Header";
import moment from "moment";
import "moment/locale/es-do";
import leftSideBar from "@/components/dashboard/menu/leftSideBar";
import _service from "@/services/orderService";

export default defineComponent({
    name: "RouteList",
    components: { Header, leftSideBar },

    data() {
        return {
            tabRole: "",
            searchByquery: "",
            drawer: ref(false),
            activeName: "first",
            page_size: 10,
            page_index: 1,
            total_count: 23,
            total_pages: 10,
            role: "Delivery",
            tableData: [
                {
                    id: "",
                    workSession: "",
                    date: "",
                    neighbourhoods: "",
                    deliveries: "",
                    orderCount: "",
                },
            ],
            multipleSelection: [],
            loading: false,
        };
    },
    created() {
        this.getAllOrders(10, 1);
        this.moment = moment;
    },
    mounted() {
        this.tabRole = "Delivery";
        this.moment.locale("es-do");
    },
    methods: {
        async handlePassed(index, row) {
            try {
                await _service.verificationSuccess(row.id);
                this.tableData = this.tableData.filter((t) => t.id != row.id);
            } catch (error) {
                console.error(error);
            }
        },
        async handleFail(index, row) {
            try {
                await _service.verificationFail(row.id);
                this.tableData = this.tableData.filter((t) => t.id != row.id);
            } catch (error) {
                console.error(error);
            }
        },
        async handlePacked(index, row) {
            try {
                await _service.packOrder(row.id);
                this.tableData = this.tableData.filter((t) => t.id != row.id);
            } catch (error) {
                console.error(error);
            }
        },
        handleSizeChange(val) {
            console.log(`${val} items per page`);
        },
        // onTabChanged(name) {
        //     this.tabRole = name.props.label;
        // },
        handleCurrentChange(val) {
            const data = {
                PageSize: this.page_size,
                PageIndex: val,
                Role: this.role,
            };
            _service.getOrders(data).then((user) => {
                this.tableData = user.data.items;
            });
        },

        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        searchByQuery(busqueda) {
            const data = {
                Query: busqueda != "" ? busqueda : this.searchByquery,
                PageSize: this.page_size,
                PageIndex: this.page_index,
                Role: this.role,
            };
            _service.getOrders(data).then((user) => {
                this.tableData = user.data.items;
            });
        },
        getAllOrders(page_size, page_index) {
            const data = {
                PageSize: page_size,
                PageIndex: page_index,
            };
            this.loading = true;
            _service
                .getOrders(data)
                .then((data) => {
                    this.total_pages = data.data.totalPages;
                    this.total_count = data.data.pageSize;
                    this.tableData = data.data.items;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
});
</script>
<style scoped>
i.color-icon {
    color: white;
}
</style>
